// src/components/Header.js

import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Box, Link, Button } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import logo from "../assets/logo.png"; // Adjust the path as necessary

const Header = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <AppBar position="static" sx={{ backgroundColor: "white", color: "black" }}>
            <Toolbar>
                {/* <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }}>
                    <MenuIcon />
                </IconButton> */}
                <Link href="/" color="inherit" underline="none" sx={{ display: "flex", alignItems: "center" }}>
                    <img src={logo} alt="Company Logo" style={{ height: 40, marginRight: 8 }} />
                    {/* <Typography variant="h6">Company Name</Typography> */}
                </Link>
                {!isMobile && (
                    <Box sx={{ flexGrow: 1, display: "flex", justifyContent: "flex-end" }}>
                        <Button color="inherit" href="#about">
                            About Us
                        </Button>
                        <Button color="inherit" href="#services">
                            Services
                        </Button>
                        {/* <Button color="inherit" href="#blog">
                            Blogs
                        </Button> */}
                        {/* <Button color="inherit" href="#contact">
                            Contact
                        </Button> */}
                    </Box>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Header;
