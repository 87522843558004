// src/components/Footer.js

import React from "react";
import { Box, Typography, Container, Link, Grid } from "@mui/material";

const Footer = () => {
    return (
        <Box
            sx={{
                py: 3,
                px: 2,
                mt: "auto",
                backgroundColor: (theme) => (theme.palette.mode === "light" ? theme.palette.grey[200] : theme.palette.grey[800]),
            }}
        >
            <Container maxWidth="md">
                <Grid container spacing={4}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6" gutterBottom>
                            Contact Us
                        </Typography>
                        <Typography variant="body1">
                            <strong>Email:</strong>{" "}
                            <Link href="mailto:info@gaits.tech" color="inherit">
                                info@gaits.tech
                            </Link>
                        </Typography>
                        <Typography variant="body1" paragraph>
                            <strong>Office Address:</strong>
                            <br />
                            Laxmisagar, Bomikhal, Rasulgarh,
                            <br />
                            Bhubaneswar - 751010 Odisha
                            <br />
                            India
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="h6" gutterBottom>
                            Our Expertise in solving GIS problems
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Emergency Response Delays
                            <br />
                            Delivery Challenges
                            <br />
                            Service Inefficiency
                            <br />
                            Navigation Confusion
                            <br />
                            Security Risks
                            <br />
                            Property Management
                            <br />
                            Urban Planning Difficulties
                            <br />
                            Accessibility Concerns
                            <br />
                            Tourism and Accessibility
                        </Typography>
                    </Grid>
                </Grid>
                <Typography variant="body2" color="text.secondary" align="center" sx={{ mt: 2 }}>
                    {"Copyright © "}
                    <Link color="inherit" href="https://gaits.tech/">
                        GEO AI Technology Solutions Private Limited
                    </Link>{" "}
                    {new Date().getFullYear()}
                    {"."}
                </Typography>
            </Container>
        </Box>
    );
};

export default Footer;
