// src/components/Hero.js

import React from "react";
import { Box, Typography, Button, Container } from "@mui/material";
import backgroundImage from "../assets/background2.jpg"; // Adjust the path as necessary
import logo from "../assets/logo.png"; // Adjust the path as necessary

const Hero = () => {
    return (
        <Box
            sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "80vh",
                backgroundImage: `url(${backgroundImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                textAlign: "center",
                py: { xs: 4, md: 8 },
                px: { xs: 2, md: 4 },
                color: "white",
                "::before": {
                    content: '""',
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(0, 0, 0, 0.2)", // Semi-transparent overlay
                    zIndex: 1,
                },
            }}
        >
            <Container maxWidth="lg" sx={{ position: "relative", zIndex: 2 }}>
                <div style={{ display: "inline-block", backgroundColor: "rgba(240, 240, 240, 0.6)", padding: 10, borderRadius: 10 }}>
                    <img src={logo} alt="gaits.tech" style={{ height: 100, marginRight: 8 }} />
                </div>
                <Typography variant="h2" gutterBottom sx={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)" }}>
                    Discover local intelligence with us
                </Typography>
                <Typography variant="h5" gutterBottom sx={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.7)" }}>
                    GEO AI Technology Solutions Private Limited
                </Typography>
                <Button variant="contained" color="primary" size="large" href="#services" sx={{ mt: 4 }}>
                    Learn More
                </Button>
            </Container>
        </Box>
    );
};

export default Hero;
