// src/components/AboutUs.js

import React from "react";
import { Box, Typography, Container, Grid, Paper, Avatar } from "@mui/material";
import profile1 from "../assets/profile1.jpg";
import profile2 from "../assets/profile2.jpg";
import profile3 from "../assets/profile3.jpg";

const teamMembers = [
    {
        name: "Rabindranath Nanda",
        title: "",
        image: profile1,
    },
    {
        name: "Satyakam Panigrahi",
        title: "",
        image: profile2,
    },
    {
        name: "Basavaraj B Pujar",
        title: "",
        image: profile3,
    },
];

const AboutUs = () => {
    return (
        <Container id="about" sx={{ py: { xs: 4, md: 8 } }}>
            <Typography variant="h4" gutterBottom>
                About Us
            </Typography>
            <Typography variant="body1" paragraph>
                We are a leading company in our industry, committed to providing top-notch services and solutions to our clients. Our team of experts is dedicated to ensuring your success and satisfaction.
            </Typography>
            <Typography variant="h4" gutterBottom sx={{ mt: 4 }}>
                Our Team
            </Typography>
            <Grid container spacing={4}>
                {teamMembers.map((member, index) => (
                    <Grid item key={index} xs={12} sm={6} md={4}>
                        <Paper sx={{ p: 2, textAlign: "center" }}>
                            <Avatar src={member.image} alt={member.name} sx={{ width: 100, height: 100, margin: "auto", mb: 2 }} />
                            <Typography variant="h6" gutterBottom>
                                {member.name}
                            </Typography>
                            <Typography variant="body1">{member.title}</Typography>
                        </Paper>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default AboutUs;
