// src/components/Services.js

import React from "react";
import { Typography, Container, Grid, Paper } from "@mui/material";
import logo from "../assets/logo.png";

const services = [
    { title: "Service 1", description: "Description of service 1" },
    { title: "Service 2", description: "Description of service 2" },
    { title: "Service 3", description: "Description of service 3" },
];

const Services = () => {
    return (
        <Container id="services" sx={{ py: { xs: 4, md: 8 } }}>
            {/* <div style={{ display: "inline-block", backgroundColor: "rgba(240, 240, 240, 0.6)", padding: 10, borderRadius: 10 }}> */}
            <img src={logo} alt="gaits.tech" style={{ height: 100, marginRight: 8 }} />
            {/* </div> */}
            <Typography variant="h4" gutterBottom>
                Our Services
            </Typography>

            <Typography variant="body" gutterBottom>
                Discover local intelligence with us. Location is the key to solving today’s challenges. Location intelligence accelerates our understanding. This has become possible by invention of NaviC system of ISRO (India), along with other GNSS technology of other countries to capture precise
                location with high accuracies on geospatial Eco System. We manage Geographic Information (GI) by collocating them in standard ways to address a variety of problems and pave the way for future innovations utilizing current domain models. We use various modern technologies like Drone
                data capture, DGPS precise location retrieval, CORS for synchronous precise position capture and RDBMS technology to keep the information securely and efficiently in Geospatial clouds for improving emergency response time, delivery efficiency, service provision, and much more. An
                indicative list of our activities is given below:
                <br></br>
                <br></br>
                <b>Solving Current GIS Problems</b>
                <div style={{ marginLeft: 20 }}>
                    <br></br>
                    <b>1. Emergency Response Delays: </b>Inaccurate GPS mapping can cause critical delays for emergency responders, potentially endangering lives.<br></br> Solution: GAITS improves GPS accuracy, enabling responders to locate specific home doors quickly and efficiently.
                </div>
                <br></br>
                <div style={{ marginLeft: 20 }}>
                    <b>2. Delivery Challenges: </b> Parcel and food delivery services may struggle to find the correct entrance, leading to delays and customer dissatisfaction. <br></br>Solution: Enhanced GPS mapping ensures precise location data, aiding delivery services in timely and accurate
                    deliveries.
                </div>
                <br></br>
                <div style={{ marginLeft: 20 }}>
                    <b>3. Service Inefficiency: </b> Utility providers and maintenance services may waste time and resources trying to locate the correct entrance.
                    <br></br>Solution: Accurate GIS data helps service providers efficiently find and access the right locations, improving overall service efficiency.
                </div>
                <br></br>
                <div style={{ marginLeft: 20 }}>
                    <b>4. Navigation Confusion: </b>Homeowners and visitors may face difficulties navigating to specific home entrances.
                    <br></br>Solution: Improved GPS mapping provides clear and accurate navigation, reducing confusion and frustration.
                </div>
                <br></br>
                <div style={{ marginLeft: 20 }}>
                    <b>5. Security Risks: </b>Inaccurate mapping can lead to unauthorized access points being used, increasing security risks for residents.
                    <br></br>Solution: Precise GIS data ensures that only authorized access points are identified and used, enhancing residential security.
                </div>
                <br></br>
                <div style={{ marginLeft: 20 }}>
                    <b>6. Property Management: </b>Challenges in documenting and managing property entrances may affect property valuation and maintenance scheduling. <br></br>Solution: Accurate mapping facilitates better documentation and management of property entrances, aiding property managers
                    in their tasks.
                </div>
                <br></br>
                <div style={{ marginLeft: 20 }}>
                    <b>7. Urban Planning Difficulties: </b>Urban planners and developers may face obstacles in designing efficient layouts and infrastructure if home door locations are not accurately mapped.
                    <br></br>Solution: High-precision GIS data supports urban planning by providing reliable location information, aiding in the design of efficient urban layouts.
                </div>
                <br></br>
                <div style={{ marginLeft: 20 }}>
                    <b>8. Accessibility Concerns: </b>Individuals with disabilities may have difficulties finding accessible entrances.
                    <br></br>Solution: Accurate GPS mapping helps identify accessible entrances, improving mobility and independence for individuals with disabilities.
                </div>
                <br></br>
                <div style={{ marginLeft: 20 }}>
                    <b>9. Tourism and Accessibility: </b>Tourists may face difficulties finding locations, impacting the tourism industry.
                    <br></br>Solution: Enhanced GIS data aids tourists in easily finding destinations, boosting tourism and local economies.
                </div>
                <br></br>
                <b>Impact on Future Innovations</b>
                <div style={{ marginLeft: 20 }}>
                    <br></br>
                    <b>1. Smart Home Technologies: </b>Inaccurate GPS mapping reduces the effectiveness of smart home systems.
                    <br></br>Solution: Precise GIS data supports the development of location-based smart home technologies, enhancing automated security, lighting, and climate control systems.
                </div>
                <br></br>
                <div style={{ marginLeft: 20 }}>
                    <b>2. Urban Mobility Solutions: </b>Limits the ability of autonomous vehicles and drones to perform tasks like package delivery or passenger pickup/drop-off.
                    <br></br>Solution: Accurate home door mapping enables autonomous vehicles and drones to navigate efficiently, supporting urban mobility solutions.
                </div>
                <br></br>
                <div style={{ marginLeft: 20 }}>
                    <b>3. Geolocation Services: </b>Advanced services such as personalized navigation and augmented reality applications require accurate door-level mapping for a seamless user experience.
                    <br></br>Solution: High-precision GIS data ensures that geolocation services function correctly, providing users with accurate and reliable information
                </div>
                <br></br>
                <div style={{ marginLeft: 20 }}>
                    <b>4. Location-Based Marketing: </b>Reduced accuracy in targeted advertising and customer engagement.
                    <br></br>Solution: Accurate geolocation data enhances the precision of location-based marketing, improving customer engagement and return on investment.
                </div>
                <br></br>
                <div style={{ marginLeft: 20 }}>
                    <b>5. IoT Integration: </b>IoT devices depending on precise geolocation data experience reduced functionality and accuracy.
                    <br></br>Solution: Reliable GIS data supports the functionality of IoT devices, enhancing their utility and efficiency.
                </div>
                <br></br>
                <div style={{ marginLeft: 20 }}>
                    <b>6. Data Analytics: </b>Inaccurate data leads to flawed analysis and suboptimal decisions.
                    <br></br>Solution: Accurate GIS data underpins big data analytics, enabling better analysis, resource allocation, and decision-making in urban planning and community services.
                </div>
            </Typography>

            <br></br>

            <Typography variant="body" gutterBottom>
                GAITS is at the forefront with their partners from private and government of solving current problems in the domain of all Engineering domains like Geomatics, Geo ICT and branches of Mapping including land records enabling future technological innovations. By improving the use of
                international standards devised by ISO/IEC, OGC, W3C, IEEE, Object Management Group blended with BIS standards and other standard operating procedures developed and mandated to be used in the country, GAITS is enhancing the efficiency and effectiveness of various services, from
                emergency disaster response and deliveries to urban planning and smart city home technologies through Digital Twins and IoT infrastructure. The impact of these improvements is far-reaching, supporting a wide range of industries, including intelligent and smart transport system, and
                paving the way for a smarter, more connected future.
            </Typography>

            {/* <Grid container spacing={4}>
                {services.map((service, index) => (
                    <Grid item key={index} xs={12} sm={6} md={4}>
                        <Paper sx={{ p: 2, textAlign: "center", height: "100%" }}>
                            <Typography variant="h6" gutterBottom>
                                {service.title}
                            </Typography>
                            <Typography variant="body1">{service.description}</Typography>
                        </Paper>
                    </Grid>
                ))}
            </Grid> */}
        </Container>
    );
};

export default Services;
