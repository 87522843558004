// src/App.js

import React from "react";
import { Box } from "@mui/material";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import AboutUs from "./components/AboutUs";
import Services from "./components/Services";
import Contact from "./components/Contact";
import Blog from "./components/Blog";
import BlogPost from "./components/BlogPost"; // Create this component for individual blog posts

const App = () => {
    return (
        <Router>
            <Box display="flex" flexDirection="column" minHeight="100vh">
                <Header />
                <Box component="main" sx={{ flexGrow: 1 }}>
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <>
                                    <Hero />
                                    <Services />
                                    {/* <Blog /> */}
                                    <AboutUs />
                                    {/* <Contact /> */}
                                </>
                            }
                        />
                        <Route path="/blog/:postId" element={<BlogPost />} /> {/* Adjust BlogPost component */}
                    </Routes>
                </Box>
                <Footer />
            </Box>
        </Router>
    );
};

export default App;
