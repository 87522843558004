// src/components/BlogPost.js

import React from "react";
import { useParams } from "react-router-dom";
import { Container, Typography, Box } from "@mui/material";

const blogPosts = {
    "post-1": {
        title: "Blog Post 1",
        content: "Full content of blog post 1...",
    },
    "post-2": {
        title: "Blog Post 2",
        content: "Full content of blog post 2...",
    },
    "post-3": {
        title: "Blog Post 3",
        content: "Full content of blog post 3...",
    },
};

const BlogPost = () => {
    const { postId } = useParams();
    const post = blogPosts[postId];

    if (!post) {
        return <Typography variant="h6">Post not found</Typography>;
    }

    return (
        <Container sx={{ py: { xs: 4, md: 8 } }}>
            <Typography variant="h4" gutterBottom>
                {post.title}
            </Typography>
            <Typography variant="body1">{post.content}</Typography>
        </Container>
    );
};

export default BlogPost;
